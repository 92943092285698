import { ref } from 'vue'
import { defineStore } from 'pinia'
import { BaseError, objectify } from '@ezio/utils'
import { useEzioAPI } from '@ezio/services'
import { localStores } from '..'
import { AgencyUser } from './types'

export const useUsersStore = defineStore('users', () => {
    const { api } = useEzioAPI()

    const all = ref<{ [id: string]: AgencyUser }>({})

    const fetchCollection = async (agencyId: string, params?: { is_manager: boolean }) => {
        const payload = Object.assign({}, params, { pagination: false })
        const response = await api.get('agencies/' + agencyId + '/users', payload)
        store(response.data)
        return response.data
    }

    const fetch = async (userId: string, agencyId: string = null) => {
        const response = agencyId ? await api.get('agencies/' + agencyId + '/users/' + userId) : await api.get('users/' + userId)
        store([response.data])
        return response.data
    }

    const attachToAgency = async (agencyId: string, params: any) => {
        const response = await api.post('agencies/' + agencyId + '/users', params)
        store([response.data])

        return response.data
    }
    const update = async (user: any, agencyId: string) => {
        try {
            const response = await api.put('agencies/' + agencyId + '/users/' + user.id, user)
            store([response.data])
            return response.data
        } catch (error: any) {
            throw new BaseError({
                title: 'Oups !',
                message: error.message
            })
        }
    }

    const sendSetupEmail = async (userId: string) => {
        const response = await api.get('/users/' + userId + '/send_setup_email')
    }

    const requestLivenessUrl = async (userId: string, agencyId: string) => {
        const response = await api.post('agencies/' + agencyId + '/users/' + userId + '/request_liveness', {
            user_id: userId
        })
        store([response.data])
        return response.data
    }

    const forceLivenessStatus = async (userId: string, agencyId: string, values: any) => {
        const response = await api.put('agencies/' + agencyId + '/users/' + userId + '/force_liveness_status', values)
        return response.data
    }

    const updateEzioStatus = async (userId: string, agencyId: string, status: number) => {
        const response = await api.put('agencies/' + agencyId + '/users/' + userId + '/ezio_status', {
            ezio_status: status
        })
        store([response.data])
        return response.data
    }

    const submitKyc = async (userId: string, agencyId: string) => {
        const response = await api.post('agencies/' + agencyId + '/users/' + userId + '/submit_kyc')
        return response.data
    }

    const notificationsOpenedAt = async () => {
        const response = await api.put('users/' + localStores.currentStore.user.id + '/notifications_seen_at')
        return response.data
    }

    const blockUser = async (userId: string, blocked: boolean) => {
        const response = await api.put('users/' + userId + '/block', { blocked })
        return response.data
    }
    const banAssociatedMandates = async (userId: string) => {
        const response = await api.post('users/' + userId + '/ban_associated_mandates')
        return response.data
    }

    const store = (users: AgencyUser[]) => {
        const ags = users.map((a) => Object.assign({}, all.value[a.id], a))
        all.value = Object.assign({}, all.value, objectify(ags))
    }

    const reset = () => {}

    return {
        all,
        fetch,
        fetchCollection,
        update,
        sendSetupEmail,
        requestLivenessUrl,
        forceLivenessStatus,
        attachToAgency,
        updateEzioStatus,
        submitKyc,
        notificationsOpenedAt,
        blockUser,
        banAssociatedMandates,
        reset
    }
})
